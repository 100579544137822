<template>
	<div class="container">
		<div class="wave">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@keyframes audio-wave {
	0% {
		height: 10px;
		transform: translateY(0px);
		background: var(--el-color-primary);
	}
	25% {
		height: 40px;
		transform: translateY(-5px) scaleY(1);
		background: var(--el-color-primary-light-9);
	}
	50% {
		height: 10px;
		transform: translateY(0px) scaleY(1);
		background: var(--el-color-primary);
	}
	100% {
		height: 10px;
		transform: translateY(0px) scaleY(1);
		background: var(--el-color-primary-light-9);
	}
}

.wave {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 60px;
}

.wave span {
	height: 18px;
	width: 10px;
	display: block;
	border-radius: 8px;
	background: var(--el-color-primary);
	animation: audio-wave 2.2s infinite ease-in-out;
}

.wave span:nth-child(2) {
	left: 11px;
	background: var(--el-color-primary-light-9);
	animation-delay: 0.2s;
}

.wave span:nth-child(3) {
	left: 22px;
	animation-delay: 0.4s;
}

.wave span:nth-child(4) {
	left: 33px;
	animation-delay: 0.6s;
}

.wave span:nth-child(5) {
	left: 44px;
	animation-delay: 0.8s;
}

.wave span:nth-child(6) {
	left: 55px;
	animation-delay: 1s;
}
</style>
